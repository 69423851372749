@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --theme-primary: #0666cd;
  --theme-secondary: #2e353a;
  font-family: 'Poppins', sans-serif;
}

.bg-theme-primary {
  background-color: var(--theme-primary);
}

.bg-theme-secondary {
  background-color: var(--theme-secondary);
}

.top-navbar{
  z-index: 100;
}

.default-btn {
  background-color: var(--theme-primary);
  color: white;
  cursor: pointer;
  padding: 8px 24px;
  border-radius: 5px;

  &:hover {
    background-color: var(--theme-secondary);
  }
}

.secondary-btn {
  background-color: var(--theme-secondary);
  color: white;
  cursor: pointer;
  padding: 8px 24px;
  border-radius: 5px;

  &:hover {
    background-color: var(--theme-primary);
  }
}

input, select, button, .ant-select-selector {
  min-height: 40px;
}

h1 {
  font-size: 2em;
  font-weight: bolder;
}

h2 {
  font-size: 1.5em;
  font-weight: bolder;
}

h3 {
  font-size: 1.17em;
  font-weight: bolder;
  line-height: 1.3;
}

h4 {
  font-size: 1em;
  font-weight: bolder;
}

h5 {
  font-size: 0.83em;
  font-weight: bolder;
}

section {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

table.cm-table,
table.cm-table td,
table.cm-table th {
  border: 1px solid;
  padding: 6px;
}

table.cm-table {
  width: 100%;
  border-collapse: collapse;
}

.required label::after {
  content: "*" !important;
  color: rgb(255, 0, 0);
  visibility: visible !important;
  padding-left: 2px;
}

a {
  color: inherit !important;
}

.css-dev-only-do-not-override-1nnzd11.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  background-color: var(--theme-primary);
}

.upload-dissable {
  background-color: #c1c1c1;
  opacity: 0.5;
  cursor: not-allowed;
}

.truncate-text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.truncate-2-text {
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.dashboard main {
  margin-left: 268px;
  /* width: calc(100vw - 256px); */
  background-color: #f1f1f1;
  min-height: 100vh;
  transition: all 1s;
  transition-timing-function: ease-in-out;
}

.aside-menu {
  background-color: var(--theme-primary);
  width: 256px;
  color: #ffffff;
  position: fixed;
  left: 5px;
  top: 5px;
  bottom: 5px;
  padding: 14px 0;
  margin: 0;
  border-radius: 10px;
  overflow: hidden;
  transition: all 1s;
  transition-timing-function: ease-in-out;
  z-index: 9;
}

.aside-menu ul li {
  cursor: pointer;
  border-radius: 8px;
  padding: 0.5rem 0.85rem;
  margin: 0.5rem 0.75rem;
  transition: all 0.1s;
  transition-timing-function: ease;
}

.aside-menu ul li.active {
  background-color: var(--theme-secondary);
}

.aside-menu ul li:hover {
  background-color: var(--theme-secondary);
}

footer {
  background-color: var(--theme-secondary);
  color: #ffffff;
}

.cm-table-responsive {
  width: 100%;
  overflow-x: auto;
}

.ant-modal-title {
  text-transform: capitalize;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ck-editor__editable {
  min-height: 300px;
}

.css-dev-only-do-not-override-vieoz1.ant-input-affix-wrapper, .css-dev-only-do-not-override-vieoz1.ant-picker-large{
  padding: 0 10px !important;
}

@media (min-width: 1024px) {
  .navbar-select-width {
    width: 200px;
  }
}

@media (min-width: 1280px) {
  .navbar-select-width {
    width: 250px;
  }
}

.course-category-block {
  cursor: pointer;
  background-color: var(--theme-primary);
}

.ytp-chrome-top.ytp-show-cards-title {
  display: none;
}

::placeholder {
  color: red;
}

.navbar-custom-select .css-dev-only-do-not-override-1nnzd11.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid #1b1714;
}

.navbar-custom-select .css-dev-only-do-not-override-1nnzd11.ant-select .ant-select-selection-placeholder {
  color: #000000;
}

.cm-aside-menu {
  overflow-y: auto;
  height: calc(100vh - 231px);
  padding-bottom: 4px;
}

.cm-aside-menu::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.cm-aside-menu::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.cm-aside-menu::-webkit-scrollbar-thumb {
  background: #6e6e6e;
}

/* Handle on hover */
.cm-aside-menu::-webkit-scrollbar-thumb:hover {
  background: #969696;
}

.application-delete-btn {
  position: absolute !important;
  top: -6px;
  right: -16px;
}

.loader {
  --dim: 3rem;
  width: var(--dim);
  height: var(--dim);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  animation: spin988 2s linear infinite;
}

.loader .circle {
  --color: #333;
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%,
  25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%,
  50% {
    transform: scale(1) rotate(180deg);
  }

  70%,
  75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.cm-loader-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: rgb(255 255 255 / 80%);
}

.profile-edit-btn {
  position: absolute !important;
  top: 20px;
  right: 20px;
}

.dashboard {
  background-color: #f1f1f1;
}

.dashboard .css-dev-only-do-not-override-1nnzd11.ant-btn-primary {
  background-color: #ffd700;
  color: #424242;
}

@media (max-width: 1023px) {
  .aside-menu {
    left: -280px;
  }

  .dashboard main {
    margin-left: 0px;
  }
}

.css-dev-only-do-not-override-1nnzd11.ant-table-wrapper .ant-table-thead>tr>th,
.css-dev-only-do-not-override-1nnzd11.ant-table-wrapper .ant-table-thead>tr>td {
  background: var(--theme-primary);
  color: #ffffff;
}


.row-expired {
  background-color: #FEE2E2;
}

.row-expired .ant-table-cell-row-hover {
  background-color: #FEE2E2 !important;
}